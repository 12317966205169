@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;700&display=swap');

:root {
  --primary-color: #5B6A8E;
  --secondary-color: #364568;
  --color-white: #fff;
  --color-light-blue: #71aef5;
  --color-green: #8BC34A;
}
body {
  background: var(--primary-color);
  font-family: 'Raleway', sans-serif;

}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.app {
  text-align: center;
  width: 100%;
  height: 100%;
}
.header {
  background: none;
  position: fixed;
  top: 20px;
  left: 20px;
  width: 100%;
  text-align: left;
}
.logo {
  width: 380px;
  max-width: 400px;
}
.temperature {
  float: right;
  padding: 15px 35px;
  color: var(--color-white);
  font-size: 50px;
}
.overallStatus {
  background: #182d41 url(images/bg.png) no-repeat;
  background-size: cover;
  margin: 10px 20px;
  padding: 25px 12px 25px 12px;
  border-radius: 20px;;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: var(--color-light-blue);
  font-size: 40px;
  font-weight: 700;
}
.overallStatusAlert {
  background: #145da0 url(images/bg.png) no-repeat;
  background-size: cover;
  margin: 10px 20px;
  padding: 25px 12px 25px 12px;
  border-radius: 20px;;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: var(--color-white);
  font-size: 40px;
  font-weight: 700;
}
.smallText {
  padding-top: 20px;
  font-size: 40px;;
}
.spacer {
  height: 120px;
}
.qualityFactor {
  margin: 10px 20px;
  padding: 3px 12px;
  border-radius: 20px;
  height: 135px;
  text-align: left;
  font-family: 'Raleway', sans-serif;
  background: var(--secondary-color);
  color: var(--color-white);
  font-size: 1.1rem;
  font-weight: 700 !important;
}
.qualityFactorIcon {
  float: left;
  width: 10%;
  padding: 2% 1% 1% 1%;
}
.qualityFactorText {
  float: right;
  width: 83%;
}
.qualityFactorText h1 {
  margin-bottom: 20px;
  margin-top: 15px;
}
.iconSmall {
  width: 100px;
  padding: 0px 10px;
  max-height: 100px;
  max-width: 80px;
}
.statusBar {
  margin: 5px !important;
  height: 40px;
}
.statusBar2 {
  margin: 5px !important;
  height: 30px;
  border-radius: 20px;
  background-color: #8BC34A;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  color: #fff;
  text-align: center;
  height: 20px;
  padding-top: 3px;
  white-space: nowrap;
  font-size: 200px;
  text-align: right;
  transition: ease 2s;
}

.right-justify {
  float:right;
}
.restoringAlert {
  font-size: 1.5rem;
  margin-top: 0;
}
.spinner {
  width: 50px;
}
.tagLine h1 {
  font-weight: 700;
  font-size: 3.0rem;
  color: var(--color-green);
}
.tagLineAlert h1 {
  font-weight: 700;
  font-size: 3.0rem;
  color: var(--color-light-blue);
}


/* Phone Sizing */
@media (max-width: 600px) {
  .logo {
    width: 200px;
  }
  .temperature {
    float: right;
    padding: 10px 30px;
    color: var(--color-white);
    font-size: 20px;
  }
  .overallStatus {
    font-size: 25px;
  }
  .overallStatusAlert {
    font-size: 25px;
  }
  .spacer {
    height: 80px;
  }
  .qualityFactor {
    font-size: 8px;
    max-height: 100px;
  }
  .qualityFactorIcon {
    width: 15%;
  }
  .qualityFactorText {
    width: 80%;
  }
  .iconSmall {
    width: 40px;
    padding: 0px 5px;
  }
  .statusBar {
    height: 30px;
  }
  .tagLine h1 {
    font-size: 1.8rem;
  }
}
